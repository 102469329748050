import Repository from "@/repositories/api.js"
const resource = "/support_organizations"

export default {
  async get() {
    let response = await Repository.get(`${resource}/contact_info`)
    return response.data.support_organization
  },
  async update(organizationData) {
    let response = await Repository.patch(`${resource}/update`, {
      support_organization: organizationData
    })
    return response.data
  }
}
