<template>
  <base-form
    v-slot="slotProps"
    :model="organization"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    model-name="Veranstalter"
  >
    <el-form-item label="Name" prop="name">
      <el-input v-model="slotProps.model.name" v-focus></el-input>
    </el-form-item>

    <el-form-item label="Adresse" prop="address">
      <el-input v-model="slotProps.model.address"></el-input>
    </el-form-item>

    <el-form-item label="Postleitzahl" prop="zipcode">
      <el-input v-model="slotProps.model.zipcode"></el-input>
    </el-form-item>

    <el-form-item label="Ort" prop="city">
      <el-input v-model="slotProps.model.city"></el-input>
    </el-form-item>

    <el-form-item label="Telefon" prop="phone">
      <el-input v-model="slotProps.model.phone"></el-input>
    </el-form-item>

    <el-form-item label="Website" prop="url">
      <el-input v-model="slotProps.model.url"></el-input>
    </el-form-item>

    <el-form-item label="Name Kontaktperson" prop="contact_person_name">
      <el-input v-model="slotProps.model.contact_person_name"></el-input>
    </el-form-item>

    <el-form-item label="E-Mail-Adresse" prop="email">
      <el-input v-model.trim="slotProps.model.email"></el-input>
    </el-form-item>

    <el-form-item
      v-if="isSuperAdmin || isRegionUser"
      label="Kann Veranstaltungen ohne Prüfung eintragen"
    >
      <el-switch v-model="slotProps.model.trusted"> </el-switch>
    </el-form-item>
  </base-form>
</template>

<script>
import { mapGetters } from "vuex"
import BaseForm from "@/components/forms/BaseForm"

export default {
  components: {
    BaseForm
  },
  props: {
    organization: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "Bitte Namen angeben", trigger: "blur" }
        ],
        address: [
          { required: true, message: "Bitte Adresse angeben", trigger: "blur" }
        ],
        zipcode: [
          {
            required: true,
            message: "Bitte gültige PLZ angeben",
            trigger: "blur"
          }
        ],
        city: [
          { required: true, message: "Bitte Ort angeben", trigger: "blur" }
        ],
        phone: [
          {
            required: true,
            message: "Bitte Telefonnummer angeben",
            trigger: "blur"
          }
        ],
        contact_person_name: [
          {
            required: true,
            message: "Bitte Kontaktperson angeben",
            trigger: "blur"
          }
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Bitte gültige E-Mail-Adresse angeben",
            trigger: "blur"
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["isSuperAdmin", "isRegionUser"])
  }
}
</script>
