<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Mein Profil bearbeiten">
      <support-organization-form
        :loading="loading"
        :organization="organization"
        :form-submitted="saveSupportOrganization"
        mode="edit"
      ></support-organization-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import SupportOrganizationForm from "@/components/forms/SupportOrganizationForm"
import SupportOrganizationRepository from "@/repositories/organization/support_organizations_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Profil bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    SupportOrganizationForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      organization: {}
    }
  },
  async created() {
    try {
      this.organization = await SupportOrganizationRepository.get()
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveSupportOrganization(organization) {
      await SupportOrganizationRepository.update(organization)
    }
  }
}
</script>
